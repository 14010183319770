import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import TwoTrucks from "../components/svg-assets/TwoTrucks";

import driverOnTheRoad from "../images/driver-on-the-road.jpg";
import walkingInFrontOfATruck from "../images/walking-in-front-of-a-truck.jpg";
import talkingInsideAWarehouse from "../images/talking-inside-a-warehouse.jpg";
import roadWithTrees from "../images/road-with-trees.jpg";
import fretlinkOffices from "../images/fretlink-offices.jpg";
import palm from "../images/palm.svg";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { MesureIcon, ReduceIcon, CompensateIcon } from "../components/svg-assets/Icons";

import stylesheet from "./index.module.css";
import { BlogPostCarousel } from "../components/BlogPostCarousel";

const STEP_COUNT = 5;
const STEP_PAUSE = 2; // Pause two intervals between the reset of step animations
const STEP_INTERVAL = 2500; // in milliseconds

const IndexPage = () => {
  const { t } = useTranslation();

  const [carriersStep, setCarriersStep] = useState(3);
  const [shippersStep, setShippersStep] = useState(3);

  useEffect(() => {
    const intervalCarriers = setInterval(() => {
      setCarriersStep(carriersStep => (carriersStep % (STEP_COUNT + STEP_PAUSE)) + 1);
    }, STEP_INTERVAL);
    const intervalShippers = setInterval(() => {
      setShippersStep(shippersStep => (shippersStep % (STEP_COUNT + STEP_PAUSE)) + 1);
    }, STEP_INTERVAL);
    return () => {
      clearInterval(intervalCarriers);
      clearInterval(intervalShippers);
    };
  }, []);

  return (
    <Layout>
      <SEO title={t("home.html-title")} />
      <div className="fl-bg-bubble" style={{ left: "calc(50% - 552px - 415px)", top: "-134px" }} />
      <div className={`fl-grid fl-top-element ${stylesheet.indexHeader}`}>
        <h1 className="fl-big-header fl-indented">
          <Trans i18nKey="home.title" />
        </h1>
      </div>

      <div className="fl-floating-image-bubble">
        <img src={driverOnTheRoad} alt="" />
      </div>
      <div className="fl-grid">
        <div className="fl-first-half fl-green-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="home.first-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="home.first-block.title" />
            </h2>
            <Trans parent="p" i18nKey="home.first-block.p-everyone" />
            <Trans parent="p" i18nKey="home.first-block.p-we-give-them-tools" />
          </div>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "105px" }}>
        <div className="fl-first-half fl-svg-width-wrapper">
          <TwoTrucks />
        </div>
        <div className="fl-second-half fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="home.second-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="home.second-block.title" />
            </h2>
            <Trans parent="p" i18nKey="home.second-block.p-using-our-tos" />
            <Trans parent="p" i18nKey="home.second-block.p-we-are-setting-a-standard" />
            <Trans parent="p" i18nKey="home.second-block.p-a-standard-where-everyone-wins" />
          </div>
        </div>
      </div>

      {/* Carriers */}
      <div className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-green-context fl-grid">
          <img className="fl-pan-bg" src={walkingInFrontOfATruck} alt="" loading="lazy" />
          <div className="fl-last-third fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="home.carriers.tag" />
            </div>
            <h2>
              <Trans i18nKey="home.carriers.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-bg-right" style={{ marginTop: "200px" }}>
        <div className="fl-bg-bubble" />
      </div>
      <div className="fl-grid">
        <div className="fl-first-half fl-medium-block" style={{ marginTop: "64px" }}>
          <h2>
            <Trans i18nKey="home.carriers.carriers-shall-optimize" />
          </h2>
          <p className="fl-strong">
            <Trans i18nKey="home.carriers.gather-the-best-for-each-km" />
          </p>
          <Trans parent="p" i18nKey="home.carriers.p-we-provide-smart-offers" />
          <p className="fl-link-next">
            <Link to="/carriers/">
              <Trans i18nKey="home.carriers.link-more-details" />
            </Link>
          </p>
        </div>
        <div
          className="fl-last-third fl-blue-context fl-sheet fl-over-sheet"
          style={{ marginTop: "-104px" }}>
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="home.carriers.progress-sheet.tag" />
          </div>
          <div className="fl-progress">
            <div
              className={`fl-progress-step ${
                carriersStep === 1 ? "fl-progress-step--current" : ""
              }`}>
              <strong>01</strong>
              <Trans parent="p" i18nKey="home.carriers.progress-sheet.01" />
            </div>
            <div
              className={`fl-progress-step ${
                carriersStep === 2 ? "fl-progress-step--current" : ""
              }`}>
              <strong>02</strong>
              <Trans parent="p" i18nKey="home.carriers.progress-sheet.02" />
            </div>
            <div
              className={`fl-progress-step ${
                carriersStep === 3 ? "fl-progress-step--current" : ""
              }`}>
              <strong>03</strong>
              <Trans parent="p" i18nKey="home.carriers.progress-sheet.03" />
            </div>
            <div
              className={`fl-progress-step ${
                carriersStep === 4 ? "fl-progress-step--current" : ""
              }`}>
              <strong>04</strong>
              <Trans parent="p" i18nKey="home.carriers.progress-sheet.04" />
            </div>
            <div
              className={`fl-progress-step ${
                carriersStep === 5 ? "fl-progress-step--current" : ""
              }`}>
              <strong>05</strong>
              <Trans parent="p" i18nKey="home.carriers.progress-sheet.05" />
            </div>
          </div>
        </div>
      </div>

      {/* Shippers */}
      <div className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-pan--left-soft-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={talkingInsideAWarehouse} alt="" loading="lazy" />
          <div className="fl-first-third fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="home.shippers.tag" />
            </div>
            <h2>
              <Trans i18nKey="home.shippers.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid fl-resp-same-line">
        <div className="fl-second-half fl-medium-block" style={{ marginTop: "64px" }}>
          <h2>
            <Trans i18nKey="home.shippers.shippers-expects-organization-excellence" />
          </h2>
          <p className="fl-strong">
            <Trans i18nKey="home.shippers.reconcile-securize" />
          </p>
          <Trans parent="p" i18nKey="home.shippers.p-help-securize" />
          <p className="fl-link-next">
            <Link to="/shippers/">
              <Trans i18nKey="home.shippers.link-more-details" />
            </Link>
          </p>
        </div>

        <div
          className="fl-first-third fl-blue-context fl-sheet fl-over-sheet"
          style={{ marginTop: "-104px" }}>
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="home.shippers.progress-sheet.tag" />
          </div>
          <div className="fl-progress">
            <div
              className={`fl-progress-step ${
                shippersStep === 1 ? "fl-progress-step--current" : ""
              }`}>
              <strong>01</strong>
              <Trans parent="p" i18nKey="home.shippers.progress-sheet.01" />
            </div>
            <div
              className={`fl-progress-step ${
                shippersStep === 2 ? "fl-progress-step--current" : ""
              }`}>
              <strong>02</strong>
              <Trans parent="p" i18nKey="home.shippers.progress-sheet.02" />
            </div>
            <div
              className={`fl-progress-step ${
                shippersStep === 3 ? "fl-progress-step--current" : ""
              }`}>
              <strong>03</strong>
              <Trans parent="p" i18nKey="home.shippers.progress-sheet.03" />
            </div>
            <div
              className={`fl-progress-step ${
                shippersStep === 4 ? "fl-progress-step--current" : ""
              }`}>
              <strong>04</strong>
              <Trans parent="p" i18nKey="home.shippers.progress-sheet.04" />
            </div>
            <div
              className={`fl-progress-step ${
                shippersStep === 5 ? "fl-progress-step--current" : ""
              }`}>
              <strong>05</strong>
              <Trans parent="p" i18nKey="home.shippers.progress-sheet.05" />
            </div>
          </div>
        </div>
      </div>

      {/* Green roads */}
      <div
        className="fl-bg-left"
        style={{ marginTop: "-360px", marginRight: "-120px", width: "calc(50% + 120px)" }}>
        <div className="fl-hider-gradient" />
        <img src={palm} alt="" loading="lazy" />
      </div>
      <div className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-pan--left-soft-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={roadWithTrees} alt="" loading="lazy" />
          <div className="fl-first-third fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="home.green-roads.tag" />
            </div>
            <h2>
              <Trans i18nKey="home.green-roads.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "72px" }}>
        <div className="fl-first-half">
          <h2>
            <Trans i18nKey="home.green-roads.the-market-waits-for-new-solutions" />
          </h2>
        </div>
        <div className="fl-second-half fl-medium-block fl-green-context">
          <p className="fl-strong">
            <Trans i18nKey="home.green-roads.initiate-decarbonization" />
          </p>
          <Trans parent="p" i18nKey="home.green-roads.p-partenered-with-wenow" />
          <p className="fl-link-next">
            <Link to="/green-roads/">
              <Trans i18nKey="home.green-roads.discover-green-road-approach" />
            </Link>
          </p>
        </div>
      </div>
      <div className="fl-grid fl-three-cards" style={{ marginTop: "72px", marginBottom: "104px" }}>
        <section>
          <MesureIcon />
          <h3>
            <code>
              <Trans i18nKey="home.green-roads.three-cards.first.number" />
            </code>
            <Trans i18nKey="home.green-roads.three-cards.first.title" />
          </h3>
          <p>
            <Trans i18nKey="home.green-roads.three-cards.first.content" />
          </p>
        </section>
        <section>
          <ReduceIcon />
          <h3>
            <code>
              <Trans i18nKey="home.green-roads.three-cards.second.number" />
            </code>
            <Trans i18nKey="home.green-roads.three-cards.second.title" />
          </h3>
          <p>
            <Trans i18nKey="home.green-roads.three-cards.second.content" />
          </p>
        </section>
        <section>
          <CompensateIcon />
          <h3>
            <code>
              <Trans i18nKey="home.green-roads.three-cards.third.number" />
            </code>
            <Trans i18nKey="home.green-roads.three-cards.third.title" />
          </h3>
          <p>
            <Trans i18nKey="home.green-roads.three-cards.third.content" />
          </p>
        </section>
      </div>

      {/* Discover our company */}
      <div className="fl-lighter-blue-wrapper">
        <div className="fl-bg-right" style={{ marginTop: "470px" }}>
          <div className="fl-bg-bubble" style={{ transform: "scaleX(-1)", marginLeft: "100px" }} />
        </div>
        <div className="fl-grid" style={{ marginTop: "120px" }}>
          <div className="fl-pan fl-pan--bottom-gradient fl-pan--multiply">
            <img className="fl-pan-bg" src={fretlinkOffices} alt="" loading="lazy" />
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "64px" }}>
          <div className="fl-first-half fl-medium-block">
            <h2>
              <Trans i18nKey="home.company.discover-our-company" />
            </h2>
            <Trans parent="p" i18nKey="home.company.p-founded-in-paris" />
            <p className="fl-link-next">
              <Link to="/about-us/">
                <Trans i18nKey="home.company.link-more-details" />
              </Link>
            </p>
          </div>

          <div
            className="fl-last-third fl-blue-context fl-sheet fl-sheet--lighter fl-over-sheet"
            style={{ marginTop: "-160px" }}>
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="home.company.fact-sheet.tag" />
            </div>
            <div className="fl-facts">
              <Trans
                parent="div"
                className="fl-fact"
                i18nKey="home.company.fact-sheet.facts.01"
                components={{ fact: <code /> }}
              />
              <Trans
                parent="div"
                className="fl-fact"
                i18nKey="home.company.fact-sheet.facts.02"
                components={{ fact: <code /> }}
              />
              <Trans
                parent="div"
                className="fl-fact"
                i18nKey="home.company.fact-sheet.facts.03"
                components={{ fact: <code /> }}
              />
              <Trans
                parent="div"
                className="fl-fact"
                i18nKey="home.company.fact-sheet.facts.04"
                components={{ fact: <code /> }}
              />
              <Trans
                parent="div"
                className="fl-fact"
                i18nKey="home.company.fact-sheet.facts.05"
                components={{ fact: <code /> }}
              />
            </div>
          </div>

          <hr className="fl-both-half" />

          <div className="fl-medium-block fl-first-half">
            <h2>
              <Trans i18nKey="home.our-blog.title" />
            </h2>
            <Trans parent="p" i18nKey="home.our-blog.p-we-explain-our-point-of-view" />
            <p className="fl-link-next">
              <a href="https://blog.fretlink.com/">
                <Trans i18nKey="home.our-blog.link-more-details" />
              </a>
            </p>
          </div>
        </div>

        <div style={{ marginTop: "40px", marginBottom: "80px" }}>
          <BlogPostCarousel />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
