import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState, useEffect, useRef } from "react";
import throttle from "../lib/throttle";

import homepageAPI from "../lib/homepage";
import { ScrollBackButton, ScrollForwardButton } from "./ScrollButtons";

const throttledScroll = setScrollLeft =>
  throttle(event => {
    setScrollLeft(event.target.scrollLeft);
  }, 100);

export const BlogPostCarousel = () => {
  const { t, i18n } = useTranslation();
  const isSSR = typeof window === "undefined";
  const [blogPosts, setBlogPosts] = useState([]);
  const [scrollLeft, setScrollLeft] = useState(0);

  const carousel = useRef();

  useEffect(() => {
    if (!isSSR && !blogPosts?.length) {
      homepageAPI.getBlogPosts(10, i18n.language).then(data => {
        data.posts.forEach(post => { post.url = process.env.BLOG_BASE_URL + post.slug; });
        setBlogPosts(data.posts);

        if (carousel.current) {
          carousel.current.addEventListener("scroll", throttledScroll(setScrollLeft), {
            passive: true,
          });
        }
      });
    }
  }, [blogPosts.length, i18n.language, isSSR]);

  return (
    <div className="fl-grid fl-both-half fl-blog-post-carousel-wrapper">
      {blogPosts?.length > 0 ? (
        <>
          <ScrollBackButton carousel={carousel} scrollLeft={scrollLeft} />
          <ScrollForwardButton carousel={carousel} scrollLeft={scrollLeft} />
        </>
      ) : undefined}
      <div className="fl-blog-post-carousel" ref={carousel}>
        {blogPosts?.length > 0
          ? blogPosts.map(post => (
              <a className="fl-blog-post fl-img-link" href={post.url} key={post.url}>
                <div className="fl-illustration" data-link-click={t("home.our-blog.read-article")}>
                  <img src={post.image} alt="" loading="lazy" />
                </div>
                <time dateTime={post.publishedDate}>
                  {new Date(post.publishedDate).toLocaleDateString(i18n.language, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </time>
                <div className="fl-blog-post-title">{post.title}</div>
              </a>
            ))
          : undefined}
      </div>
    </div>
  );
};
