import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

export const ScrollForwardButton = ({ carousel, scrollLeft }) => {
  const { t } = useTranslation();
  const carouselNode = carousel.current;

  if (carouselNode) {
    const active =
      scrollLeft > 0
        ? scrollLeft < carouselNode.scrollWidth - carouselNode.offsetWidth - 100
        : true;

    const scroll = event => {
      event.preventDefault();

      const overflowingLink = Array.from(carouselNode.childNodes).find(
        el => el.offsetLeft - scrollLeft - 100 + el.offsetWidth > carouselNode.offsetWidth
      );

      if (overflowingLink) {
        overflowingLink.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    };

    return (
      <button
        className={`fl-scroll-button-right ${active ? "fl-scroll-button--active" : ""}`}
        title={t("common.carousel.next")}
        onClick={scroll}>
        {t("common.carousel.next")}
      </button>
    );
  } else {
    return null;
  }
};

export const ScrollBackButton = ({ carousel, scrollLeft }) => {
  const { t } = useTranslation();
  const carouselNode = carousel.current;

  if (carouselNode) {
    const active = scrollLeft > 100;

    const antiScroll = event => {
      event.preventDefault();

      const overflowingLink = Array.from(carouselNode.childNodes)
        .reverse()
        .find(el => el.offsetLeft + 100 < scrollLeft);

      if (overflowingLink) {
        overflowingLink.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
      }
    };

    return (
      <button
        className={`fl-scroll-button-left ${active ? "fl-scroll-button--active" : ""}`}
        title={t("common.carousel.previous")}
        onClick={antiScroll}>
        {t("common.carousel.previous")}
      </button>
    );
  } else {
    return null;
  }
};
